import React from 'react';
import useWindow from '../../../hooks/useWindow';
import CustomImage from '../../Image/Image';
import { SecondaryDescription, SecondaryTitle } from '../../Typography';
import SectionWrapper from '../SectionWrapper';
import { CustomArrowLeft, CustomArrowRight } from './CustomArrows';

function SliderItem({ itemData, sliderRef, generateDots, customHeight }) {
  const { isMobile } = useWindow();

  return (
    <div className="slider-item" style={{minHeight: (!isMobile && isMobile !== undefined) && customHeight}}>
      <SectionWrapper>
        <div className="slider-item__container">
          <div className="slider-item__image-container">
            <CustomImage src={itemData.image} srcSet={itemData.srcSet}/>
            <div className="slider-item__dots-container">{!isMobile && generateDots()}</div>
          </div>
          <div className="slider-item__text-container">
            <div style={{height: isMobile && customHeight}}>
              <SecondaryTitle>“{itemData.description}”</SecondaryTitle>
            </div>
            <span className="slider-item__separator" />
            <div className="slider-item__text-container-bottom">
              <div className="slider-item__text-container-person">
                <SecondaryTitle>{itemData.person}</SecondaryTitle>
                <SecondaryDescription>{itemData.personInfo}</SecondaryDescription>
              </div>
              <div className="slider-item__text-container-arrows">
                {!isMobile && (
                  <>
                    <CustomArrowLeft onClick={sliderRef?.slickPrev} />
                    <CustomArrowRight onClick={sliderRef?.slickNext} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
}

export default SliderItem;
