import React from 'react';
import ArrowLeft from '../../../assets/images/icons/other/arrow-left.inline.svg';
import ArrowRightMobile from '../../../assets/images/icons/other/slider-arrow-mobile.inline.svg'

export function CustomArrowLeft({ onClick, isMobile }) {
  return (
    <div className="slider-arrow" onClick={onClick}>
      {isMobile ? (
        <ArrowRightMobile />
      ) : (
        <ArrowLeft />
      )}
    </div>
  );
}

export function CustomArrowRight({ onClick, isMobile }) {
  return (
    <div className="slider-arrow slider-arrow__reversed" onClick={onClick}>
      {isMobile ? (
        <ArrowRightMobile />
      ) : (
        <ArrowLeft />
      )}
    </div>
  );
}
