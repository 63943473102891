import React, { useCallback, useState } from 'react';
import useWindow from '../../../hooks/useWindow';
import SliderItem from './SliderItem';
import { CustomArrowLeft, CustomArrowRight } from './CustomArrows';
import { useMemo } from 'react';
import { SecondaryTitle } from '../../Typography';
import { useRef } from 'react';
import { useEffect } from 'react';
import loadable from '@loadable/component';

const Slider = loadable(() => import('react-slick'));

function SliderSection({ sliderItems, customHeight }) {
  const [sliderRef, setSliderRef] = useState(null);
  const sliderWrapperRef = useRef(null);
  const { isMobile } = useWindow();

  const settings = useMemo(
    () => ({
      autoplay: true,
      arrows: false,
      infinite: true,
      speed: isMobile ? 1200 : 750,
      slidesToShow: isMobile && sliderItems.length > 1 ? 2 : 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplaySpeed: 6000,
      pauseOnHover: false,
    }),
    [isMobile, sliderItems]
  );

  const getSliderRef = useCallback((node) => {
    setSliderRef(node);
  }, []);

  const generateDots = useCallback(
    (slideIndex) => {
      const components = [];

      sliderItems.forEach((_, index) => {
        components.push(
          <div
            key={index}
            className={`slider-item-dot ${slideIndex === index ? 'slider-item-dot-active' : ''}`}
            onClick={() => sliderRef?.slickGoTo(index)}
          />
        );
      });

      return components;
    },
    [sliderItems]
  );

  const generateItems = useCallback(() => {
    const components = [];

    sliderItems.forEach((item, index) => {
      components.push(
        <SliderItem
          key={index}
          itemData={item}
          sliderRef={sliderRef}
          generateDots={() => generateDots(index)}
          customHeight={customHeight}
        />
      );
    });

    return components;
  }, [sliderItems, sliderRef, customHeight]);

  useEffect(() => {
    if (!sliderWrapperRef?.current || !sliderRef) return;

    const handleMouseDown = () => {
      sliderRef.slickPause();
    };

    const handleMouseUp = () => {
      sliderRef.slickPlay();
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchstart', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('touchstart', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleMouseUp);
    };
  });

  return (
    <div className="slider__container" ref={sliderWrapperRef}>
      {isMobile && (
        <div className="slider__title">
          <SecondaryTitle>
            Don’t just
            <br />
            take it from us
          </SecondaryTitle>
        </div>
      )}
      <Slider {...settings} ref={getSliderRef}>
        {generateItems()}
      </Slider>
      {isMobile && sliderItems.length > 2 && (
        <div className="slider-item__text-container-arrows">
          <CustomArrowLeft onClick={sliderRef?.slickPrev} isMobile={isMobile} />
          <CustomArrowRight onClick={sliderRef?.slickNext} isMobile={isMobile} />
        </div>
      )}
    </div>
  );
}

export default SliderSection;
